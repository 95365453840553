import {
  AllowedEntityFilters,
  DefaultEntityFilters,
  EntityAutocompletePicker,
} from '@backstage/plugin-catalog-react';
import { ServiceCriticalityFilter } from './ServiceCriticality';
import { LanguageFilter } from './Language';
import { K8sClusterFilter } from './K8sCluster';
import { AwsAccountFilter } from './AwsAccount';
import { ToolingFilter } from './Tooling';
import { VisibilityFilter } from './Visibility';

export { AwsAccountFilter, AwsAccountPicker } from './AwsAccount';
export { K8sClusterFilter, K8sClusterPicker } from './K8sCluster';
export {
  ServiceCriticalityFilter,
  ServiceCriticalityPicker,
} from './ServiceCriticality';
export { LanguageFilter, LanguagePicker } from './Language';
export { ToolingFilter, ToolingPicker } from './Tooling';
export { VisibilityFilter, VisibilityPicker } from './Visibility';

export type CustomFilters = DefaultEntityFilters & {
  awsAccount: AwsAccountFilter;
  k8sCluster: K8sClusterFilter;
  language: LanguageFilter;
  serviceCriticality: ServiceCriticalityFilter;
  tooling: ToolingFilter;
  visibility: VisibilityFilter;
};

export const CustomEntityAutocompletePicker = EntityAutocompletePicker<
  CustomFilters,
  AllowedEntityFilters<CustomFilters>
>;
