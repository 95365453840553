import { OnCallReporterInfoRef } from '../types';
import {
  createApiRef,
  DiscoveryApi,
  type FetchApi,
} from '@backstage/core-plugin-api';

export const opsGenieReportApiRef = createApiRef<OpsGenieReport>({
  id: 'plugin.opsgenie-report.service',
});

export interface OpsGenieReport {
  getOnCallReportInfo(teamId: string): Promise<OnCallReporterInfoRef>;
}

const DEFAULT_PROXY_PATH = '/opsgenie-report/';

type Options = {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
};

/**
 * API to talk to Opsgenie-report.
 */
export class OpsGenieReportApi implements OpsGenieReport {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  private async fetch<T = any>(input: string): Promise<T> {
    const apiUrl = await this.getApiUrl();
    const response = await this.fetchApi.fetch(`${apiUrl}${input}`);

    if (!response.ok) {
      throw new Error(
        `Request failed with ${response.status} ${response.statusText}`,
      );
    }

    return (await response.json()) as T;
  }

  private async getApiUrl() {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return `${proxyUrl}${DEFAULT_PROXY_PATH}`;
  }

  async getOnCallReportInfo(teamId: string): Promise<OnCallReporterInfoRef> {
    return this.fetch<OnCallReporterInfoRef>(`oncall/uuid/${teamId}`);
  }
}
