import {
  configApiRef,
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import {
  AnalitycsApi,
  analyticsApiRef,
  DEFAULT_BUSINESS_HOURS_END,
  DEFAULT_BUSINESS_HOURS_START,
  OpsGenieApi,
  opsGenieApiRef,
} from './api';
import {
  OpsGenieReportApi,
  opsGenieReportApiRef,
} from './api/OpsGenieReportApi';

export const opsGeniePlugin = createPlugin({
  id: 'opsgenie',
  apis: [
    createApiFactory({
      api: opsGenieReportApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) => {
        return new OpsGenieReportApi({ discoveryApi, fetchApi });
      },
    }),

    createApiFactory({
      api: opsGenieApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
        configApi: configApiRef,
      },
      factory: ({ discoveryApi, fetchApi, configApi }) => {
        return new OpsGenieApi({
          discoveryApi: discoveryApi,
          fetchApi: fetchApi,
          domain: configApi.getString('opsgenie.domain'),
          readOnly: configApi.getOptionalBoolean('opsgenie.readOnly') ?? false,
          proxyPath: configApi.getOptionalString('opsgenie.proxyPath'),
        });
      },
    }),

    createApiFactory({
      api: analyticsApiRef,
      deps: { configApi: configApiRef },
      factory: ({ configApi }) => {
        return new AnalitycsApi({
          businessHours: {
            start:
              configApi.getOptionalNumber(
                'opsgenie.analytics.businessHours.start',
              ) || DEFAULT_BUSINESS_HOURS_START,
            end:
              configApi.getOptionalNumber(
                'opsgenie.analytics.businessHours.end',
              ) || DEFAULT_BUSINESS_HOURS_END,
          },
        });
      },
    }),
  ],
  routes: {
    explore: rootRouteRef,
  },
});

export const OnCallPage = opsGeniePlugin.provide(
  createRoutableExtension({
    name: 'OnCallPage',
    component: () =>
      import('./components/IncidentHandlingPage').then(
        element => element.IncidentHandlingPage,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const EntityOpsGenieOnCallListCard = opsGeniePlugin.provide(
  createComponentExtension({
    name: 'EntityOpsGenieOnCallListCard',
    component: {
      lazy: () =>
        import('./components/Entity').then(element => element.OnCallListCard),
    },
  }),
);
