import React from 'react';

import {
  HomePageStarredEntities,
  HeaderWorldClock,
  HomePageTopVisited,
  HomePageToolkit,
  HomePageRecentlyVisited,
  ClockConfig,
  CustomHomepageGrid,
  LayoutConfiguration,
  Breakpoint,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { Content, Header } from '@backstage/core-components';
import { GrafanaIcon } from '../../assets/icons/GrafanaIcon';
import { BugsnagIcon } from '../../assets/icons/BugsnagIcon';
import { PrometheusIcon } from '../../assets/icons/PrometheusIcon';
import { KafkaUiIcon } from '../../assets/icons/KafkaUiIcon';
import { ConfluenceIcon } from '../../assets/icons/ConfluenceIcon';
import { JiraIcon } from '../../assets/icons/JiraIcon';
import { BitwardenIcon } from '../../assets/icons/BitwardenIcon';
import { OpenSearchIcon } from '../../assets/icons/OpenSearchIcon';
import { HarborIcon } from '../../assets/icons/HarborIcon';
import { JaegerIcon } from '../../assets/icons/JaegerIcon';
import { OpsGenieIcon } from '../../assets/icons/OpsGenieIcon';
import { UnleashIcon } from '../../assets/icons/UnleashIcon';
import { ApolloStudioIcon } from '../../assets/icons/ApolloStudioIcon';
import { ITToolsIcon } from '../../assets/icons/ITToolsIcon';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

const defaultTools = [
  {
    url: 'https://rtlplus.atlassian.net/wiki/spaces/plus/pages/1025146980/Developer+Documentation',
    label: 'Developer Docs',
    icon: <MenuBookIcon />,
  },
  {
    url: 'https://rtlplus.atlassian.net/wiki/spaces/plus/pages/51164216/Event-Kalender',
    label: 'Event-Calendar',
    icon: <DateRangeIcon />,
  },
  {
    url: 'https://grafana.netrtl.com/',
    label: 'Grafana',
    icon: <GrafanaIcon />,
  },
  {
    url: 'https://kibana.prod.r5s.aws-cbc.cloud/_dashboards/app/home#/',
    label: 'Kibana/Logs',
    icon: <OpenSearchIcon />,
  },
  {
    url: 'https://prometheus.prod.r5s.aws-cbc.cloud/',
    label: 'Prometheus',
    icon: <PrometheusIcon />,
  },
  {
    url: 'https://alertmanager.prod.r5s.aws-cbc.cloud/',
    label: 'AlertManager',
    icon: <PrometheusIcon />, // Is the same icon as Prometheus, since they are from the same project
  },
  {
    url: 'https://hub.netrtl.com/',
    label: 'Harbor',
    icon: <HarborIcon />,
  },
  {
    url: 'https://cbc-dcc.app.eu.opsgenie.com/',
    label: 'OpsGenie',
    icon: <OpsGenieIcon />,
  },
  {
    url: 'https://toggle-ui.prod.r5s.aws-cbc.cloud/',
    label: 'Unleash',
    icon: <UnleashIcon />,
  },
  {
    url: 'https://studio.apollographql.com/org/rtl-deutschland/graphs',
    label: 'Apollo Studio',
    icon: <ApolloStudioIcon />,
  },
  {
    url: 'https://rtlplus.atlassian.net/wiki/spaces/plus/',
    label: 'Confluence',
    icon: <ConfluenceIcon />,
  },
  {
    url: 'https://rtlplus.atlassian.net/jira/your-work/',
    label: 'Jira',
    icon: <JiraIcon />,
  },
  {
    url: 'https://pwbucket.netrtl.com/',
    label: 'Bitwarden',
    icon: <BitwardenIcon />,
  },
  {
    url: 'https://dashboard-bugsnag.netrtl.com/cbc-cologne-broadcasting-center-gmbh',
    label: 'Bugsnag',
    icon: <BugsnagIcon />,
  },
  {
    url: 'https://kafka-ui.prod.r5s.aws-cbc.cloud/',
    label: 'Kafka UI',
    icon: <KafkaUiIcon />,
  },
  {
    url: 'https://jaeger.prod.r5s.aws-cbc.cloud/',
    label: 'Tracing',
    icon: <JaegerIcon />,
  },
  {
    url: 'https://it-tools.netrtl.com/',
    label: 'IT Tools',
    icon: <ITToolsIcon />,
  },
  {
    url: 'https://rtlplus.atlassian.net/wiki/x/HICiUw',
    label: 'Backstage FAQ',
    icon: <LiveHelpIcon />,
  },
];

const clockConfigs: ClockConfig[] = [
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
  {
    label: 'Berlin',
    timeZone: 'Europe/Berlin',
  },
  {
    label: 'Lisbon',
    timeZone: 'Europe/Lisbon',
  },
];
const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

const defaultConfig: LayoutConfiguration[] = [
  {
    component: <HomePageSearchBar />,
    x: 1,
    y: 0,
    width: 10,
    height: 5,
  },
  {
    component: <HomePageRecentlyVisited />,
    x: 0,
    y: 5,
    width: 6,
    height: 18,
  },
  {
    component: <HomePageToolkit tools={defaultTools} />,
    x: 6,
    y: 5,
    width: 6,
    height: 18,
  },
  {
    component: <HomePageStarredEntities />,
    x: 0,
    y: 23,
    width: 6,
    height: 18,
  },
];

const colBreaks: Record<Breakpoint, number> = {
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 6,
  xxs: 6,
};

export const HomePage = () => {
  // This is the default configuration that is shown to the user
  // when first arriving to the homepage.
  return (
    <>
      <Header title="RTL+ Home" pageTitleOverride="Home">
        <HeaderWorldClock
          clockConfigs={clockConfigs}
          customTimeFormat={timeFormat}
        />
      </Header>
      <Content>
        <CustomHomepageGrid
          config={defaultConfig}
          rowHeight={10}
          cols={colBreaks}
        >
          <HomePageSearchBar />
          <HomePageStarredEntities />
          <HomePageToolkit tools={defaultTools} title="Helpful tools" />
          <HomePageRecentlyVisited />
          <HomePageTopVisited />
        </CustomHomepageGrid>
      </Content>
    </>
  );
};
