import React from 'react';
import {
  EntityKindPicker,
  EntityLifecyclePicker,
  EntityOwnerPicker,
  EntityProcessingStatusPicker,
  EntityTagPicker,
  EntityTypePicker,
  UserListPicker,
} from '@backstage/plugin-catalog-react';
import {
  AwsAccountPicker,
  K8sClusterPicker,
  LanguagePicker,
  ServiceCriticalityPicker,
  ToolingPicker,
  VisibilityPicker,
} from './custom-filters';

export const CustomCatalogFilters = () => {
  return (
    <>
      <EntityKindPicker />
      <EntityTypePicker />
      <UserListPicker />
      <EntityOwnerPicker />

      {/* Start of Custom Filter components */}
      <ServiceCriticalityPicker />
      <AwsAccountPicker />
      <K8sClusterPicker />
      <LanguagePicker />
      <ToolingPicker />
      <VisibilityPicker />
      {/* End of Custom Filter components */}

      <EntityTagPicker />
      <EntityLifecyclePicker />
      <EntityProcessingStatusPicker />
    </>
  );
};
