import { SvgIcon, SvgIconProps } from '@material-ui/core';

import React from 'react';

export const ApolloStudioIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 48 48">
    <path
      fill="currentColor"
      d="M24,44C12.972,44,4,35.028,4,24S12.972,4,24,4c5.133,0,10.013,1.942,13.741,5.467	c0.401,0.379,0.419,1.013,0.039,1.414c-0.38,0.403-1.014,0.418-1.414,0.04C33.012,7.748,28.62,6,24,6C14.075,6,6,14.075,6,24	s8.075,18,18,18s18-8.075,18-18c0-1.363-0.152-2.719-0.453-4.031c-0.123-0.539,0.213-1.075,0.751-1.198	c0.535-0.127,1.074,0.212,1.198,0.751C43.83,20.98,44,22.487,44,24C44,35.028,35.028,44,24,44z"
    />
    <circle cx="38" cy="11" r="3" fill="currentColor" />
    <polygon fill="currentColor" points="25,14 21,14 15,32 19,32" />
    <polygon fill="currentColor" points="23,14 27,14 33,32 29,32" />
    <polygon fill="currentColor" points="26,29 19,29 19,25 25,25" />
  </SvgIcon>
);
