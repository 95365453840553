import type { TableColumn } from '@backstage/core-components';
import {
  CatalogTable,
  type CatalogTableColumnsFunc,
  type CatalogTableRow,
} from '@backstage/plugin-catalog';

function createServiceCriticalityColumn(): TableColumn<CatalogTableRow> {
  return {
    title: 'Service Criticality',
    field: 'entity.metadata.custom.serviceCriticality',
  };
}

export const customCatalogColumns: CatalogTableColumnsFunc =
  entityListContext => {
    if (
      !entityListContext.filters.kind ||
      entityListContext.filters.kind.value === 'component'
    ) {
      return [
        CatalogTable.columns.createNameColumn(),
        CatalogTable.columns.createSystemColumn(),
        CatalogTable.columns.createOwnerColumn(),
        CatalogTable.columns.createSpecTypeColumn(),
        createServiceCriticalityColumn(),
        CatalogTable.columns.createSpecLifecycleColumn(),
        CatalogTable.columns.createMetadataDescriptionColumn(),
      ];
    }
    return CatalogTable.defaultColumnsFunc(entityListContext);
  };
