import React from 'react';
import { Route } from 'react-router-dom';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';

import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

import { PrometheusPage } from '@internal/backstage-plugin-prometheus';
import { OnCallPage } from '@internal/backstage-plugin-opsgenie';
import { DeploymentTrackerPage } from '@internal/backstage-plugin-deployment-tracker';

import { HomepageCompositionRoot, VisitListener } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';

import { OpsGenieIcon } from './assets/icons/OpsGenieIcon';
import { ConfluenceIcon } from './assets/icons/ConfluenceIcon';
import { JiraIcon } from './assets/icons/JiraIcon';
import { PrometheusIcon } from './assets/icons/PrometheusIcon';
import { KafkaUiIcon } from './assets/icons/KafkaUiIcon';
import { MiroIcon } from './assets/icons/MiroIcon';
import { GrafanaIcon } from './assets/icons/GrafanaIcon';
import { JaegerIcon } from './assets/icons/JaegerIcon';
import { HarborIcon } from './assets/icons/HarborIcon';
import { BugsnagIcon } from './assets/icons/BugsnagIcon';
import { GitlabIcon } from './assets/icons/GitlabIcon';
import { BitwardenIcon } from './assets/icons/BitwardenIcon';
import { UnleashIcon } from './assets/icons/UnleashIcon';
import { ThanosIcon } from './assets/icons/ThanosIcon';
import { LookerStudioIcon } from './assets/icons/LookerStudioIcon';
import { JfrogIcon } from './assets/icons/JfrogIcon';
import { OpenSearchIcon } from './assets/icons/OpenSearchIcon';
import { MsTeamsIcon } from './assets/icons/MsTeamsIcon';
import { SonarQubeIcon } from './assets/icons/SonarQubeIcon';
import { customCatalogColumns } from './components/catalog/customCatalogColumns';
import { CustomCatalogFilters } from './components/catalog/CustomCatalogFilters';

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        provider={{
          id: 'azure-auth-provider',
          title: 'Login',
          message: 'With RTL SSO',
          apiRef: microsoftAuthApiRef,
        }}
      />
    ),
  },
  icons: {
    opsgenie: OpsGenieIcon,
    msteams: MsTeamsIcon,
    confluence: ConfluenceIcon,
    jira: JiraIcon,
    prometheus: PrometheusIcon,
    kafkaui: KafkaUiIcon,
    miro: MiroIcon,
    grafana: GrafanaIcon,
    harbor: HarborIcon,
    jaeger: JaegerIcon,
    bugsnag: BugsnagIcon,
    gitlab: GitlabIcon,
    bitwarden: BitwardenIcon,
    unleash: UnleashIcon,
    thanos: ThanosIcon,
    lookerstudio: LookerStudioIcon,
    jfrog: JfrogIcon,
    opensearch: OpenSearchIcon,
    run: DirectionsRunIcon,
    sonarqube: SonarQubeIcon,
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>

    <Route
      path="/catalog"
      element={
        <CatalogIndexPage
          columns={customCatalogColumns}
          filters={<CustomCatalogFilters />}
        />
      }
    />

    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/prometheus" element={<PrometheusPage />} />
    <Route path="/deployments" element={<DeploymentTrackerPage />} />
    <Route path="/onCall" element={<OnCallPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
